import React from 'react'
import '../App.css'
// import { Button } from './Button'
import './HeroSection.css'

function HeroSection() {
    return (
        <div className='hero-container' id='home'>
            <video src='/videos/video-1.mp4'
                autoPlay loop muted />
            {/* <h1>Sam Chen's Portfolio</h1> */}
            <img
                src='images/samlogo.png'
                alt=''
                style={{width: 700, height: 700, borderRadius: 10}}
            />
            {/* <p>Articulate conversationalist and passionate Frontend Developer </p> */}
            <div className='hero-btns'>
             
                {/* <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'>
                    My Resume
                    <i class="fas fa-arrow-right"></i>
                    
                </Button> */}
            </div>
        </div>
    );
}

export default HeroSection
