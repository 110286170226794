import React from 'react'
import '../../App.css'
import Cards from '../Cards';
import ProjectsHero from '../ProjectsHero';

export default function Projects() {
    return (
        <>

        <ProjectsHero />
        <Cards />
       
        </>
    );
}