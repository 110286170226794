import React from 'react'
import '../Contact.css'
import ContactHero from '../ContactHero';


function Contact() {

    return (
        <>
            <ContactHero />

        </>
    );
}

export default Contact;