import React from 'react'
import '../App.css'
import { Button } from './Button'
import './HeroSection.css'

function ProjectsHero() {
    return (
        <div className='hero-container'>
            <video src='/videos/video-1.mp4'
                autoPlay loop muted />
            <h1>Projects</h1>
            <p> </p>
            <div className='hero-btns'>
                  
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'>
                    My Resume
                    <i class="fas fa-arrow-right"></i>
                    
                </Button>
            </div>
        </div>
    );
}

export default ProjectsHero
