import React from 'react'
import '../../App.css'
import AboutMeHero from '../AboutMeHero';

export default function AboutMe() {
    return (
        <>
            <AboutMeHero />

        </>
    );
}