import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton)

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='home' spy={true} smooth={true} offset={-100} duration={500} className='nav-links' onClick={closeMobileMenu}>
                        Sam Chen
                        <img src='images/samlogo2.png' alt='' style={{width: 50, height: 50, borderRadius: 10, marginLeft: 10}}/>
                        {/* <i className='fab fa-typo3' /> */}
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='home' spy={true} smooth={true} offset={-100} duration={500} className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='cards' spy={true} smooth={true} offset={-80} duration={500} className='nav-links' onClick={closeMobileMenu}>
                                Projects
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='about-me' spy={true} smooth={true} offset={-80} duration={500} className='nav-links' onClick={closeMobileMenu}>
                                About Me
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='contact-me' spy={true} smooth={true} offset={-80} duration={500} className='nav-links' onClick={closeMobileMenu}>
                                Contact Me
                            </Link>
                        </li>
                    </ul>
                    {button && <Button buttonStyle='btn--outline'>My Resume</Button>}
                </div>
            </nav>
        </>
    );
}

export default Navbar;
