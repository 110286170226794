import React from 'react';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import AboutMe from './AboutMe';
import Contact from './Contact'
import Footer from '../Footer';

function Home() {
    return (
        <>
            <HeroSection />
            <Cards />
            <AboutMe />
            <Contact />
            <Footer/>
        </>
    );
}

export default Home;