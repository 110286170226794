import React, { useState } from 'react'
import '../components/Contact.css'
import emailjs from 'emailjs-com'
import { init } from 'emailjs-com';
init("user_itQlD2yJeNQSBj21RG3vr");

function ContactHero() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        if (name && email && message) {
           // send mail
            const serviceId = 'service_402l6cs';
            const templateId = 'template_n4w2i4f';
            const userId = 'user_itQlD2yJeNQSBj21RG3vr';
            const templateParams = {
                name,
                email,
                message
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => console.log(response))
                .then(error => console.log(error));
    
            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
        } else {
            alert('Please fill in all fields.');
        }
    }

    //To-Do: implement validating emails
    // const isValidEmail = email => {
    //     const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return regex.test(String(email).toLowerCase());
    // };

    //To-Do: make the thank you message only show when they submit a message
    return (
        <div className='contact-me'>
            <form>      
                <input name="name" type="text" class="feedback-input" placeholder="Name"  onChange={e => setName(e.target.value)}/>   
                <input name="email" type="text" class="feedback-input" placeholder="Email" onChange={e => setEmail(e.target.value)}/>
                <textarea name="text" class="feedback-input" placeholder="Comment" onChange={e => setMessage(e.target.value)}></textarea>
                <input type="submit" value="SUBMIT" onClick={submit}/>
                <span className={emailSent ? 'visible' : null} type="confirm-message">Thank you for your message, I will be in touch with you soon!</span>
            </form>
        </div>
        
    );

}

export default ContactHero
