import React from 'react'
// import { Button } from './Button'
import './Footer.css'
import { Link } from 'react-router-dom'


function Footer() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className=' footer-subscription-heading'>
                    {/*Join the Adventure newsletter to receive our best vacation deals*/}
                </p>
                <p className='footer-subscription-text'>
                    {/*You can unsubscribe at any time.*/}
                </p>
                <div className='input-areas'>
                    {/*<form>
                        <input
                            className='footer-input'
                            name='email'
                            type='email'
                            placeholder='Your Email'
                        />
                        <Button buttonStyle='btn--outline'>Subscribe</Button>
                    </form>*/}
                </div>
            </section>
            {/*<div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>About Me</h2>
                        <Link to='/sign-up'>How it works</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Careers</Link>
                        <Link to='/'>Investors</Link>
                        <Link to='/'>Terms of Service</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>Contact Me</h2>
                        <Link to='/sign-up'>How it works</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Careers</Link>
                        <Link to='/'>Investors</Link>
                        <Link to='/'>Terms of Service</Link>
                    </div>

                </div>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Videos</h2>
                        <Link to='/'>Submit Video</Link>
                        <Link to='/'>Ambassadors</Link>
                        <Link to='/'>Agency</Link>
                        <Link to='/'>Influencer</Link>
                    </div>
                    <div class='footer-link-items'>
                        <h2>Social Media</h2>
                        <Link to='/'>Instagram</Link>
                        <Link to='/'>Facebook</Link>
                        <Link to='/'>Youtube</Link>
                        <Link to='/'>Twitter</Link>
                    </div>
                </div>
                </div>*/}
            <section className='social-media'>
                <div className='social-media-wrap'>
                    <div className='footer-logo'>
                        <Link to='/' className='social-logo'>
                            Sam Chen <img src='images/samlogo2.png' alt='' style={{width: 50, height: 50, borderRadius: 10, marginLeft: 10}}/>
                        </Link>
                    </div>
                    <small className='website-rights'>Sam Chen © 2022</small>
                    <div className='social-icons'>
                        <a href="https://www.linkedin.com/in/sam-chen-39909114a/" target="_blank" rel="noreferrer" class='social-icon-link'>
                        <i class='fab fa-linkedin' />
                        </a>
                        <a href="https://twitter.com/Abbsimp" target="_blank" rel="noreferrer" class='social-icon-link'>
                        <i class='fab fa-twitter' />
                        </a>
                        <a href="https://www.instagram.com/abstractprototype/" target="_blank" rel="noreferrer" class='social-icon-link'>
                        <i class='fab fa-instagram'></i>
                        </a>
                        <a href="https://facebook.com/spagodi" target="_blank" rel="noreferrer" class='social-icon-link'>
                        <i class='fab fa-facebook-f'></i>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer
