import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

const projects = {
  coin_grabber: "www.devpost.com/software/coin-grabber",
  soiree: "www.youtube.com/shorts/YGvKsy5rpr4",
  duck_chat: "www.github.com/abstractprototype/DuckChat_New_Generation",
  python_shooting_game: "www.github.com/abstractprototype/Python-Shooting-RPG",
  // discord_bot: 'www.replit.com/@worldofmonke/Discord-Bot#main.py'
};

// my cards
function Cards() {
  return (
    <div className="cards" id="cards">
      <h1>Check out my Projects!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/coingrabber.png"
              text="Coin Grabber: An OpenCV project game that tracks hand movements to grab virtual coins on screen."
              label="Python"
              path={`//${projects.coin_grabber}`}
            />
            <CardItem
              src="images/soireeart.jpg"
              text="Soiree: A mobile app designed to recommend the best foods/places in the surrounding area to users."
              label="React Native"
              path={`//${projects.soiree}`}
            />
            <CardItem
              src="images/duckchatart.png"
              text="Duck Chat: A social media app for college with live feed"
              label="React Native"
              path={`//${projects.duck_chat}`}
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/awsart.jpg"
              text="Python Shooting Side-scrolling game"
              label="Python"
              path={`//${projects.python_shooting_game}`}
            />
            <CardItem
              src="images/ecommerce.png"
              text="Ecommerce website for Steam Bot Workshop company"
              label="React JS"
              path="/projects"
            />
            {/* <CardItem
                            src='images/chatroomart.jpg'
                            text='Discord Bot: An automated management system for user permissions'
                            label='Python'
                            path={`//${projects.discord_bot}`}
                        /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
